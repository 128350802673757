.section {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: linear-gradient(0deg, rgba(18, 19, 20, 0.4) 0%, rgba(18, 19, 20, 0.4) 100%),
		url("../../../../public/images/banner-m.jpg") no-repeat 75% 50% / cover;
	z-index: 0;

	@media (min-width: 920px) {
		background: linear-gradient(0deg, rgba(18, 19, 20, 0.4) 0%, rgba(18, 19, 20, 0.4) 100%),
			url("../../../../public/images/banner.jpg") no-repeat 50% 50% / cover;
	}
	@media (min-width: 1280px) {
		background-position: 40% 50%;
	}
}

.container {
	display: flex;
	align-items: flex-end;
	height: 100%;
	padding-bottom: 40px;

	@media (min-width: 920px) {
		align-items: center;
		justify-content: center;
		padding-bottom: 0;
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 12px;
	width: 100%;

	text-align: center;

	@media (min-width: 920px) {
		padding-top: 56px; //Значение равно высоте хедера
	}

	p {
		max-width: 860px;
		color: #b5b5b5;
	}
}

.title {
	font-size: 22px;
	width: 100%;
	color: white;

	@media (min-width: 600px) {
		font-size: 28px;
	}

	@media (min-width: 1280px) {
		font-size: 52px;
	}
}
