.title {
	margin-top: 40px;
	font-size: 18px;
}

.subTitle {
	font-size: 18px;
}

.description {
	margin-top: 16px;
	margin-bottom: 40px;

	color: rgba(51, 51, 51, 0.78);
	p ul {
		margin-top: 10px;
		list-style-type: circle;
		margin-left: 40px;

		li {
			color: rgba(51, 51, 51, 0.78);
		}
	}
}

@media screen and (min-width: 1280px) {
	.title {
		font-size: 32px;
		line-height: 130%;
	}

	.subTitle {
		font-size: 24px;
	}

	.description {
		font-size: 20px;
		line-height: 145%;
	}
}
