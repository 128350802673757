.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	padding: 40px 0;
	margin: 0 auto;
	max-width: 1136px;

	text-align: center;

	@media (min-width: 1280px) {
		padding: 80px 0;
		gap: 40px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;

		@media (min-width: 1280px) {
			gap: 16px;
		}
	}

	.image {
		object-fit: contain;
		width: 100%;
	}
}

.horizontal {
	@media (min-width: 1280px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		padding: 40px 0;
		max-width: 100%;
		gap: 32px;

		text-align: start;

		.image {
			max-width: 698px;
		}
	}
}

.horizontalReverse {
	@media (min-width: 1280px) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;

		padding: 80px 0;
		max-width: 100%;
		gap: 32px;

		text-align: start;

		.image {
			max-width: 670px;
		}
	}
}
