.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 40px 0;

	@media (min-width: 1280px) {
		flex-direction: row;
		padding: 120px 0;
		gap: 60px;
	}
}

.title {
	text-transform: uppercase;
	text-align: center;

	@media (min-width: 1280px) {
		white-space: nowrap;
	}
}

.text {
	text-align: center;

	@media (min-width: 1280px) {
		text-align: start;
	}
}
