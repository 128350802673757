.container {
	position: relative;
	z-index: 1;
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (min-width: 420px) {
		align-items: center;
	}
}

.upperSection {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	width: 100%;

	@media (min-width: 1440px) {
		padding-top: 32px;
	}
}

.rightSection {
	display: flex;
	align-items: center;
	gap: 24px;
}

.link {
	display: flex;
	gap: 5px;
	align-items: center;
	transition: color linear 0.3s;
	color: white;

	@media (min-width: 1024px) {
		&:hover {
			color: #b5b5b5;
			text-decoration: underline;
		}
	}
}

.icon {
	display: block;

	@media (min-width: 768px) {
		display: none;
	}
}

.iconBlack {
	svg path {
		fill: black;
	}
}

.phone {
	display: none;

	@media (min-width: 768px) {
		display: block;
	}

	@media (min-width: 1280px) {
		font-size: 24px;
	}
}
