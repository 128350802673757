.headerWrapper {
	position: relative;
	height: 560px;

	@media (min-width: 1920px) {
		height: 776px;
	}
}

.image {
	object-fit: contain;
	width: 100%;
}
