.wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	p {
		margin: 0;
		padding-top: 3px;
		text-transform: uppercase;
		color: white;
	}
}

.image {
	width: 120px;
	@media (min-width: 425px) {
		width: 180px;
	}
	@media (min-width: 1024px) {
		width: 230px;
	}
}

.divider {
	width: 1px;
	height: 100%;
	opacity: 0.36;
	background: #b5b5b5;
}
